$industryText:#4e5157;

.industries{
	.industries-line{
		border-bottom:1px solid $grayBackground;
		height:65px;
		li{
			width:110px;
			height:60px;
			height:auto;
			padding:$halfPadd;
			float:left;
			p{
				@include textDecorator($industryText, $latoregular, 12px, 0);
			}
		}
	}
}
/** start dark red no hover button colors **/

$buttonTextColor:#fff;
$buttonTextBackground:#c81b26;
$buttonTextBorderColor:#91161d;

$buttonTextBackgroundHover:#c81b26;
$buttonTextBorderHover:#91161d;

$buttonIconColor:#fff;
$buttonIconBackground:#cd1e28;
$buttonIconBorderColor:#97181f;

$buttonIconBackgroundHover:#cd1e28;
$buttonIconBorderHover:#97181f;

/** start dark red no hover button colors **/
/** include framework dependencies **/

@import './../framework/_Factories';
@import './../framework/_AbstractClasses';
@import './../framework/_Constants';
@import './../framework/_Transformers';

/** end include dependencies **/

/** include home sections **/

@import './../sections/general/header';
@import './../sections/home/_slider';
@import './../sections/home/_about';
@import './../sections/home/_chart';
@import './../sections/home/_industries';
@import './../sections/home/_become_a_member';
@import './../sections/home/_members_widget';
@import './../sections/home/_nearshoring_guide';
@import './../sections/home/_news';
@import './../sections/home/_task_forces';
@import './../sections/home/_banner';
@import './../sections/home/_parallax';
@import './../sections/general/footer';

/** end include sections **/



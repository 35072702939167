

$becomeTitle:#0f1d41;
$becomeLine:#dcdcdc;
$becomeMemberPerk:#4e5157;


$buttonTextColor:#fff;
$buttonTextBackground:#0d1a39;
$buttonTextBorderColor:#040a1a;

$buttonIconColor:#fff;
$buttonIconBackground:#d6222d;
$buttonIconBorderColor:#95171f;


.become-a-member-block{
	height:500px;
	.nrcc-guy-holder{
		height:100%;
	}
	.nrcc-guy{
		bottom:0;
		left:-75px;
	}

	.details{
		padding-top:160px;
		h2{
			margin:0 0 20px 0;
			a{
				@include textDecorator($becomeTitle, $latobold, 36px, 0);
				@include LinkDecorationNone();
				text-transform:uppercase;
			}
		}
		.membership-container{
			margin-bottom:$halfMargin;
			.membership-lines-top{
				margin-right:10px;
			}
			.membership-lines-bottom{
				margin-right:10px;
			}
			.membership-lines-top, .membership-lines-bottom{
				@include rectangle(140px, 32px);
				.membership-lines{
					&.bottom{
						margin-top:30px;
					}
					float:left;
					@include rectangle(140px, 1px);
					background:$becomeLine;

				}
			}
			.membership-icon{
				background:url(#{$homeUrl}become_a_member_icon.png) 0 0 no-repeat;
				@include rectangle(39px, 42px);
				margin-right:10px;
			}
		}
		.become-a-member-perks{
			.become-a-member-list{
				li{
					@include textDecorator($becomeMemberPerk, $latobold, 20px, 0);
					line-height:24px;
					text-align:center;
					text-transform:uppercase;
					margin-bottom:$halfMargin;
				}
			}
		}

		.site-button{
			@extend .SiteButtonAbstract;
			@include CenterContainer();
			.text{
				@include ColorFactory($buttonTextColor, $buttonTextBackground, $buttonTextBorderColor);
			}
			.icon{
				@include ColorFactory($buttonIconColor, $buttonIconBackground, $buttonIconBorderColor);
				background:$buttonIconBackground url(#{$homeUrl}details_icon.png) 14px 14px no-repeat;
			}
		}
	}
}

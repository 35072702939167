/** start members details section **/

$typeBackground:#0b1630;
$membersTitleText:#4e5157;
$widgetBorder:#dcdcdc;
$numberBorder:#dcdcdc;
$numberColor:#0f1d41;
$numberTextBorder:#f1f1f1;

$verticalLineBackground:#d9232e;

$buttonTextBackground:#0f1d40;
$buttonTextBorderColor:#040a1a;
$buttonTextColor:#fff;

$buttonIconBackground:#cc1d28;
$buttonIconBorder:#95171f;
$buttonIconColor:#fff;

.members-details{
	.members-widget{
		margin:$fullMargin 0;
		height:114px;
		border-bottom:1px solid $widgetBorder;
		.widget{
			.number{
				@include rectangle(50px, 87px);
				@include BorderRadiusGeneral(3px);
				margin-right:12px;
				border:1px solid $numberBorder;
				text-align:center;
				.text{
					@include rectangle(100%, 100%);
					@include textDecorator($numberColor, $latobold, 36px, 0);
					border:2px solid $numberTextBorder;
					text-decoration:none;
					&:hover{
						text-decoration:none;
					}
				}
			}
		}
		.text{
			@include textDecorator($membersTitleText, $latobold, 36px, 0);
			line-height:40px;
			text-transform:uppercase;
			padding-top:25px;
		}
	}
	.members-info-list{
		li{
			height:60px;
			margin-bottom:$halfMargin;
			.rhombus-holder{
				z-index:4;
				.icon-holder{
					@extend .main-rhombus;
					@include rectangle(40px, 39px);
					.inner-rhombus{
						top:2px;
						@include rectangle(36px, 35px);
						.calendar-icon{
							@include rectangle(20px, 24px);
							@include Rotate(225deg);
							background:url(#{$homeUrl}become_a_member_small.png) 0 0 no-repeat;
							top:5px;
							left:7px;
						}
					}
				}
			}
			.members-info-text{
				@include textDecorator($membersTitleText, $latobold, 20px, 0);
				line-height:24px;
				margin-left:60px;
				width:180px;
			}
			.site-button{
				@extend .SiteButtonAbstract;
				.text{
					@include ColorFactory($buttonTextColor, $buttonTextBackground, $buttonTextBorderColor);
				}
				.icon{
					@include ColorFactory($buttonIconColor, $buttonIconBackground, $buttonIconBorderColor);
					background:$buttonIconBackground url(#{$homeUrl}details_icon.png) 14px 14px no-repeat;
				}
			}
			.vertical-line{
				@include rectangle(1px, 20px);
				background:$verticalLineBackground;
				z-index:1;
				bottom:-7px;
				left:19px;
			}
		}
	}
}
/**
  *
  * HOME SLIDER STYLES
  *
  */


$animatedBoxColor:#0f1d41;
$highlightedTextColor:#d9232e;
.slider-block{
	min-height:842px;
	overflow:hidden;
	margin-top:-4px;
	z-index:1;
	.slider-list{
		height:916px;
		z-index:1;
		.slick-track{
			height:916px;
		}
		div{
			.animated-figure{
				top:350px;
				left:230px;
				opacity:0;
				.triangle-left{
					@include triangle($animatedBoxColor, 120px, 120px, 'top-right', 'isosceles');
					z-index:3;
					position:relative;
				}
				.main-box{
					@include rectangle(500px, 120px);
					padding-left:80px;
					background:$animatedBoxColor;
					z-index:2;
					position:relative;
					p{
						width:500px;
						display:block;
					}
				}
				.triangle-right{
					@include triangle($animatedBoxColor, 120px, 120px, 'bottom-left', 'isosceles');
					position:relative;
					z-index:1;
				}
				h3 a{
					color:white;
					text-decoration:none;
					@include textDecorator(white, $latobold, 23px, 0);
				}
				p{
					@include textDecorator(white, $latomedium, 20px, 0);
					.highlight{
						color: $highlightedTextColor;
					}
				}
			}
			.rhombus-holder{
				top:298px;
				left:298px;
				z-index:4;
				opacity:0;
				.icon-holder{
					@extend .main-rhombus;
					.inner-rhombus{
						@extend .inner-rhombus;
						top:2px;
						.calendar-icon{
							@include rectangle(48px, 47px);
							@include Rotate(45deg);
							background:url(#{$homeUrl}calendar_event_banner.png) 0 0 no-repeat;
							top:30px;
							left:30px;
						}
					}
				}
			}
			.semi-transparent-rectangle-top{
				@include rectangle(400px, 60px);
				@include Rotate(45deg);
				background:rgba(255, 255, 255, 0.5);
				top:150px;
				top: 111px;
				height: 169px;
				width: 608px;
				left: -109px;
				z-index: 1;
				opacity:0;
			}
			.semi-transparent-rectangle-bottom{
				@include rectangle(400px, 60px);
				transform:rotate(-45deg);
				background:rgba(255, 255, 255, 0.5);
				z-index:1;
				bottom: -251px;
				left: -110px;
				width: 608px;
				height: 169px;
				opacity:0;
			}
			.image-holder{
				top:0;
				left:0;
				z-index:0;
				.full-img{
					width:100%;
					height:auto;
				}
			}
		}

	}
}

/** end slider section **/

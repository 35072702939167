

$guideText:#4e5157;
$guideTitle:#0f1d41;
$guideBackground:#d8dde1;

$buttonTextColor:white;
$buttonTextBackground:#d7222d;
$buttonTextBorderColor:#971820;

$buttonIconColor:white;
$buttonIconBackground:#d5212c;
$buttonIconBorderColor:#90151d;


.guide{
	height:602px;
	background:$guideBackground url(#{$homeUrl}nearshoring_guide_background.png) 0 0 no-repeat;
	.home-guide-text{
		@include rectangle(285px, 280px);
		left:265px;
		top:155px;
		.download{
			@include textDecorator($guideText, $latobold, 20px, 0 0 10px 0);
			line-height:24px;
			text-transform:uppercase;
			text-align:center;
		}
		h3{
			@include textDecorator($guideTitle, $latobold, 36px, 0 0 20px 0);
			line-height:40px;
			text-transform:uppercase;
			text-align:center;
		}
		p{
			@include textDecorator($guideTitle, $latoregular, 14px, 0 0 20px 0);
			text-align:center;
			line-height:17px;
		}
		.site-button{
			@extend .SiteButtonAbstract;
			@include CenterContainer();
			.text{
				@include ColorFactory($buttonTextColor, $buttonTextBackground, $buttonTextBorderColor);
			}
			.icon{
				@include ColorFactory($buttonIconColor, $buttonIconBackground, $buttonIconBorderColor);
				background:$buttonIconBackground url(#{$homeUrl}nearshoring_icon.png) 11px 9px no-repeat;
			}
		}
	}
}
/** start animation list**/
$bleumarineBackground:#0f1d41;
$redBackground:#ca1c27;
$grayBackground:#4e5157;
$grayBorder:#4e5157;


.graph{
	position:relative;
	height:410px;
	margin-bottom:30px;
	.bar-list{
		min-height:400px;
		position:relative;
		margin-bottom:5px;
		div{
			width:15px;
			position:absolute;
			bottom:0;
		}
		.bleumarine{
			background:$bleumarineBackground;
		}
		.red{
			background:$redBackground;
		}
		.gray{
			background:$grayBackground;
		}
	}
	.romboidal-list{
		clear:both;
		height:25px;
		z-index:2;
		position:absolute;
		bottom:-25px;
		li{
			position:absolute;
		}
		.bleumarine{
			@include diamondSquare($bleumarineBackground, 15);

		}
		.red{
			@include diamondSquare($redBackground, 10);
			margin-left:30px;
			margin-top:3px;
		}
		.gray{
			@include diamondSquare($grayBackground, 12);
			left:53px;
			margin-top:2px;
		}
	}
	.romboidal-line{
		height:1px;
		background:$grayBackground;
		position:absolute;
		margin:auto;
		height:1px;
		border:0;
		z-index:1;
		bottom:19px;
		bottom:-7px;
	}
}


/** end animation list **/

/** start banner section **/

.banner{
	padding:40px 0;
	.centered-banner{
		@include rectangle(722px, 85px);
		margin:0 auto;
		padding:0;
	}
}

/** end banner section **/
/**
  *
  * START FOOTER STYLES
  *
  */

$footerBackground:#4e5157;

footer{
	@include rectangle(100%, 160px);
	background:$footerBackground;
	#footerLogo{
		@include rectangle(206px, 74px);
		margin-top:25px;
	}
}
.links-container{
	padding-top:25px;
	.links{
		li{
			margin:0 $fullMargin 0 0;
			a{
				@include textDecorator(white, $latoregular, 14px, 0);
				line-height:18px;
				text-transform:uppercase;
				text-decoration:none;
				&:hover{
					text-decoration:none;
				}
			}
		}
	}
}
.register-links{
	padding-top:25px;
	.register-to-newsletter{
		.icon{
			@include square(40px);
			background: $newsletterIconBackground url(#{$homeUrl}newsletter-icon.png) 9px 7px no-repeat;
			margin: 0 0 10px 0;
		}
		.text{
			@include textDecorator(white, $latoregular, 14px, 0);
			line-height:22px;
			text-transform: uppercase;
		}
	}
}

.media-links{
	padding-top:25px;
	.media{
		margin-bottom:10px;
		.facebook{
			background: url(#{$homeUrl}facebook.png) 0 0 no-repeat;
			@include square(20px);
			margin-right:15px;
		}
		.twitter{
			background: url(#{$homeUrl}twitter.png) 0 0 no-repeat;
			@include rectangle(25px, 19px);
		}
	}
	.made-by{
		@include textDecorator(white, $latolightitalic, 14px, 0);
		@include rectangle(100%, 20px);
		clear:both;
		line-height:18px;
	}
}

/** end footer**/
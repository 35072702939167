/** about us section **/

$bannerHeaderBackground:#0f1d41;
$bannerBodyBackground:#4e5157;
$bannerFooterBackground:#d9232e;
$aboutBackground:#0f1d41;

$inputContainerBorder:#c6c6c6;

.about-section{
	background:$aboutBackground;
	min-height:740px;
}
.about-section-child{
	top:-130px;
	z-index:2;
	.block{
		padding:0 $halfPadd;
	}
}
.convertor{
	.header{
		background:$bannerHeaderBackground;
		text-align:center;
		height:75px;
		padding-top:20px;
		text-transform:uppercase;
		h3{
			@include textDecorator(white, $latomedium, 20px, 0);
			line-height:24px;
		}
	}
	.body{
		background:$bannerBodyBackground;
		height:153px;
		padding-top:$halfPadd;
		p{
			@include textDecorator(white, $latomedium, 14px, 0 0 $halfMargin 0);
			line-height:18px;
			text-align:center;
		}
		.line{
			margin-bottom:10px;
			label{
				@include textDecorator(white, $latomedium, 14px, 0 0 $halfMargin 0);
				width:60px;
				display:block;
				padding-left:$halfPadd;
				padding-top:9px;
			}
			.input-container{
				@include rectangle(208px, 38px);
				border:1px solid $inputContainerBorder;
				&.first{
					margin-right:$halfPadd;
				}
				&.last{
					margin-left:$halfPadd;
				}
			}

			.value-input{
				@extend .ConvertorInput;
				width:206px;
			}
			.result-input{
				@extend .ConvertorInput;
				width:206px;
			}
		}
	}
	.results{
		background:$bannerFooterBackground;
		height:68px;
		.line{
			padding-top:$halfPadd;
		}
		label{
			&.first{
				width:60px;
			}
			&.last{
				width:49px;
			}
			@include textDecorator(white, $latomedium, 14px, 0 0 $halfMargin 0);
			width:60px;
			display:block;
			padding-left:13px;
			padding-top:0px;
		}
		.input-container{
			@include rectangle(118px, 37px);
			border:1px solid $inputContainerBorder;
		}
		.result-input{
			@extend .ConvertorInput;
			width:117px;
		}

	}
}

.calendar{
	.calendar-container{
		h1{
			@include textDecorator(white, $latomedium, 20px, 0);
		}
	}

}

.ads-banner-small{
	.header{
		background:$bannerFooterBackground;
		text-align:center;
		height:75px;
		padding-top:20px;
		h3{
			@include textDecorator(white, $latomedium, 20px, 0);
			text-transform:uppercase;
		}
	}
	.banner-place{
		height:206px;
		background:white;
	}
}

@import '../../framework/buttons/DarkRedNoHover';

.about-bottom{
	padding-top:200px;
	.title{
		text-align:center;
		h2{
			margin-bottom:60px;
			a{
				@include textDecorator(white, $latobold, 36px, 0);
				text-decoration:none;
				text-transform:uppercase;
			}
		}
	}

	.section-header{
		p.description{
			font-family:$latolight;
		}
	}
	.block{
		padding:$halfPadd;
		.rhombus{
			left:50%;
			margin:0 0 $fullMargin 50%!important;
			transform:translate(-50%, 0) rotate(45deg);
			-moz-transform:translate(-50%, 0) rotate(45deg);
			-webkit-transform:translate(-50%, 0) rotate(45deg);

			@extend .main-rhombus;
			.inner-rhombus{
				@extend .inner-rhombus;

			}
			.icon{
				@include Rotate(225deg);
			}
			.about-icon{
				@include rectangle(40px, 29px);
				background:url(#{$homeUrl}about_nrcc.png) 0 0 no-repeat;
				top:33px;
				left:31px;
			}
			.board-icon{
				@include rectangle(40px, 31px);
				background:url(#{$homeUrl}nrcc_board.png) 0 0 no-repeat;
				top:38px;
				left:29px;
			}
			.executive-icon{
				@include rectangle(36px, 40px);
				background:url(#{$homeUrl}executive_team.png) 0 0 no-repeat;
				top:30px;
				left:30px;
			}
			.members-icon{
				@include rectangle(40px, 37px);
				background:url(#{$homeUrl}members_about_nrcc.png) 0 0 no-repeat;
				top:30px;
				left:30px;
			}
		}
		.title{
			h4{
				a{
					@include textDecorator(white, $latoregular, 20px, 0 0 20px 0);
					line-height:24px;
					text-decoration:none;
					text-transform: uppercase;
					&:hover{
						text-decoration:none;
					}
				}
			}
		}
		.content{
			p{
				@include textDecorator(white, $latolight, 14px, 0 0 20px 0);
				line-height:17px;
				text-align:center;
			}
		}
		.site-button{
			@include CenterContainer();
			@include CreateSiteButton();
			.icon{
				background-image:url(#{$homeUrl}see_more_arrow.png) 12px 12px no-repeat;
			}
		}
	}

}

.members{
	clear:both;
}

/** end about us section **/
